import React, { Fragment, useEffect, useRef, useState } from "react";
import "../../styles/Farm.css";
import "../../styles/Fish.css";
import axios from "axios";
import { ApiLink, getDevice, notify, Title } from "../../utils/Title";
import { Link, useNavigate } from "react-router-dom";
import UserBoardLayout from "../../components/Layouts/BasicLayout/UserBoardLayout";
import Footer from "../../components/Layouts/BasicLayout/Footer";
import NotifyLayout from "../../components/Layouts/BasicLayout/NotifyLayout";
import AdSenseAd from "../../components/AdSenseAd/AdSenseAd";
import MultiplexAd from "../../components/AdSenseAd/MultiplexAd";
import FormFishStore from "../../components/Layouts/UserLayout/Form/FormFishStore";
import FormFishStock from "../../components/Layouts/UserLayout/Form/FormFishStock";
import { socket } from "../../socket";
import BangXepHangCauCa from "../../components/Layouts/BasicLayout/BangXepHangCauCa";
export default function FishPage() {
  let [loading, isLoading] = useState(true);
  const [info, setInfo] = useState(null);
  let navigate = useNavigate();
  let [username, setUsername] = useState("");
  let [showNotify, setShowNotify] = useState(false);
  let [quangCau, isQuangCau] = useState(1);
  let [timeCaCanCau, setTimeCaCanCau] = useState(null);
  const [bxhCauCa, setBXHCauCa] = useState([]);
  const [fishList, setFishList] = useState([]);
  const [dungCuCauCa, setDungCuCauCa] = useState([]);
  const [showStore, setShowStore] = useState(false);
  const [showStock, setShowStock] = useState(false);
  const [userMoiVao, setUserMoiVao] = useState(null);
  const [userList, setUserList] = useState([]);
  const [userNumber, setUserNumber] = useState(10);
  const [userMoiCauDuocCa, setUserMoiCauDuocCa] = useState(null);
  const timeRef = useRef(timeCaCanCau);

  useEffect(() => {
    document.title = Title.mobile_app + Title.origin;

    if (!socket) return;

    const handleUpdateUsers = (userCauCaList) => {
      const user_length = userCauCaList.length < 3 ? 5 : userCauCaList.length;
      setUserNumber(user_length);
      setUserList(userCauCaList);
    };
    const handleNewUser = (user) => setUserMoiVao(user);
    const handleNewFish = (user) => setUserMoiCauDuocCa(user);

    socket.on("update_users_cau_ca", handleUpdateUsers);
    socket.on("user_cau_ca_moi_vao", handleNewUser);
    socket.on("get_user_moi_cau_duoc_cau_ca", handleNewFish);

    return () => {
      socket.off("update_users_cau_ca", handleUpdateUsers);
      socket.off("user_cau_ca_moi_vao", handleNewUser);
      socket.off("get_user_moi_cau_duoc_cau_ca", handleNewFish);
    };
  }, []);

  const handleSetShowNotify = () => {
    setShowNotify(!showNotify);
  };

  // useEffect(() => {
  //   if (timeCaCanCau === null || timeCaCanCau <= 0) return;

  //   const interval = setInterval(() => {
  //     console.log("time", timeCaCanCau);

  //     setTimeCaCanCau((prevTime) => {
  //       if (prevTime === 1) {
  //         notify(true, "Cá cắn câu rồi, giật thôi!");
  //         // isQuangCau(2);
  //         return null; // Dừng bộ đếm
  //       }
  //       return prevTime - 1; // Giảm thời gian
  //     });
  //   }, 1000);

  //   return () => clearInterval(interval); // Dọn dẹp interval khi unmount hoặc state thay đổi
  // }, [timeCaCanCau]);

  useEffect(() => {
    if (timeCaCanCau === null || timeCaCanCau <= 0) return;

    timeRef.current = timeCaCanCau; // Cập nhật giá trị ref mỗi lần state thay đổi

    const interval = setInterval(() => {
      if (timeRef.current === 1) {
        notify(true, "Cá cắn câu rồi, giật thôi!");
        isQuangCau(2);
        setTimeCaCanCau(null); // Dừng bộ đếm
      } else {
        setTimeCaCanCau((prevTime) => prevTime - 1); // Giảm thời gian
      }
    }, 1000);

    return () => clearInterval(interval); // Dọn dẹp interval
  }, [timeCaCanCau]);

  useEffect(() => {
    let isMounted = true;

    const getUserInfo = async () => {
      try {
        const response = await axios.get(`${ApiLink.domain}/account/info`, {
          withCredentials: true,
        });
        if (isMounted && response.data.status) {
          setUsername(response.data.data.username);
          setInfo(response.data.data);
          socket.emit("join_game_cau_ca", response.data.data);
        } else if (response.data.must === "login") {
          window.localStorage.clear();
          navigate("/account/login", { replace: true });
        }
      } catch (error) {
        console.error("Lỗi khi lấy thông tin người dùng:", error);
      }
    };

    const getAllFish = async () => {
      try {
        const response = await axios.get(`${ApiLink.domain}/fish`, {
          withCredentials: true,
        });
        if (isMounted && response.data.status) {
          setFishList(response.data.data);
        }
      } catch (error) {
        console.error("Lỗi khi lấy danh sách cá:", error);
      }
    };

    const getDungCuCauCa = async () => {
      try {
        const response = await axios.get(
          `${ApiLink.domain}/user-fish/dung-cu`,
          { withCredentials: true }
        );
        if (isMounted && response.data.status) {
          setDungCuCauCa(response.data.data);
        }
      } catch (error) {
        console.error("Lỗi khi lấy dụng cụ câu cá:", error);
      }
    };

    const getBXHCauCa = async () => {
      try {
        const response = await axios.get(
          `${ApiLink.domain}/event/ket-qua/bxh-cau-ca`,
          { withCredentials: true }
        );
        if (isMounted && response.data.status) {
          setBXHCauCa(response.data.data);
        }
      } catch (error) {
        console.error("Lỗi khi lấy bảng xếp hạng câu cá:", error);
      }
    };

    const getAll = async () => {
      await Promise.allSettled([
        getUserInfo(),
        getAllFish(),
        getDungCuCauCa(),
        getBXHCauCa(),
      ]);
      if (isMounted) isLoading(false);
    };

    getAll();

    return () => {
      isMounted = false; // Dọn dẹp khi component unmount
    };
  }, [navigate]);

  // const generateRandomPx = (min, max) => {
  //   const randomValue = Math.floor(Math.random() * (max - min + 1)) + min;
  //   return randomValue + "px";
  // };

  const renderChoNgoi = () => {
    let ids = 2;
    let new_arr = [];
    userList.forEach((user, index) => {
      if (user.username !== info.username && index < 4) {
        new_arr.push(
          <div className={"chongoi_" + ids} key={index}>
            <div className="username_top">{user.username}</div>
            <img alt="user" src="/images/fish/user-cau-ca.gif" />
            <img
              alt="user"
              src="/images/fish/quangcan.gif"
              style={{
                marginBottom: "-4px",
                marginLeft: "-4px",
              }}
            />
          </div>
        );
        ids++;
      }
    });

    return new_arr;
  };

  const handleQuangCau = async () => {
    const { device_name, device_browser, device_ip, ip_detail } =
      await getDevice();
    const device = {
      device_name,
      device_browser,
      device_ip,
      ip_detail,
    };
    const response = await axios.post(
      `${ApiLink.domain + "/user-fish/quang-can-cau"}`,
      { device },
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      const date_ca_can_cau = new Date(response.data.time);
      const currentTime = new Date();
      const time_con_lai = Math.abs(date_ca_can_cau - currentTime);
      const seconds = Math.floor((time_con_lai % (1000 * 60)) / 1000);
      console.log("seconds", seconds);
      setTimeCaCanCau(seconds);
      isQuangCau(4); // 4: Chờ
      setFishList(response.data.fish_list);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/account/login", { replace: true });
      } else if (response.data.must === "permission") {
        return;
      }
    }
  };

  const handleGiatCa = async () => {
    const { device_name, device_browser, device_ip, ip_detail } =
      await getDevice();
    const device = {
      device_name,
      device_browser,
      device_ip,
      ip_detail,
    };
    const response = await axios.post(
      `${ApiLink.domain + "/user-fish/giat-can-cau"}`,
      { device },
      {
        withCredentials: true,
      }
    );

    setDungCuCauCa(response.data.dung_cu);
    if (response.data.status) {
      socket.emit("send_user_moi_cau_duoc_cau_ca", {
        ...info,
        fish_weight: response.data.fish_weight,
        fish: response.data.fish,
      });
      notify(true, response.data.message);
      isQuangCau(3);
      setFishList(response.data.fish_list);
    } else {
      isQuangCau(1);
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/account/login", { replace: true });
      } else if (response.data.must === "permission") {
        return;
      }
    }
  };

  const handleSaveClick = async () => {
    try {
      await axios.get(`${ApiLink.domain}/user-fish/click`, {
        withCredentials: true,
      });
    } catch (error) {}
  };

  return loading ? (
    <Fragment></Fragment>
  ) : (
    <Fragment>
      <AdSenseAd />
      {showStore || showStock ? (
        <div
          className="background_black"
          id="background_black"
          style={{ display: "block" }}
        >
          {showStore ? (
            <FormFishStore
              closeFormAdd={() => setShowStore(false)}
              loadData={(data) => {
                setInfo({
                  ...info,
                  vnd: data.vnd,
                });
              }}
            />
          ) : (
            ""
          )}
          {showStock ? (
            <FormFishStock
              closeFormAdd={() => setShowStock(false)}
              loadData={(data) => {
                setInfo({
                  ...info,
                  vnd: data.vnd,
                });
              }}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      <div className="farm_container">
        {/* <ul>
          {userList.map((user, index) => (
            <li key={index}>{user.username + " " + user.id_socket}</li>
          ))}
        </ul>
        <div>
          <p>Time: {timeCaCanCau ? timeCaCanCau : 0}</p>
        </div> */}
        <UserBoardLayout
          Element={null}
          info={info}
          setInfo={setInfo}
          username={username}
          setUsername={setUsername}
          handleShowNotify={handleSetShowNotify}
          showNotify={showNotify}
        />
        {showNotify && <NotifyLayout />}
        <div className="farm_content">
          <div
            style={{
              position: "relative",
            }}
            className="list_name_no_border"
          >
            Thống kê hiện tại
          </div>
          <div
            className="farm_action"
            style={{
              width: "100%",
            }}
          >
            <div
              className="fish_notify"
              style={{
                width: "100%",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  margin: "8px 0px",
                }}
              >
                <div>
                  - Hiện có <b style={{ color: "red" }}>{userNumber}</b> người
                  đang câu trong hồ.
                </div>
                {userMoiVao && (
                  <div>
                    - Chào mừng bạn{" "}
                    <b style={{ color: "red" }}>{userMoiVao.username}</b> mới
                    vào câu.
                  </div>
                )}
                {userMoiCauDuocCa && (
                  <div>
                    - Chúc mừng bạn{" "}
                    <b style={{ color: "rgb(40, 167, 69)" }}>
                      {userMoiCauDuocCa.username}
                    </b>{" "}
                    vừa câu được con{" "}
                    <b style={{ color: "red" }}>
                      {userMoiCauDuocCa.fish.fish_name}
                    </b>{" "}
                    nặng {userMoiCauDuocCa.fish_weight}Kg.
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="farm_name">Khu Câu Cá</div>
          <div className="caucanen">
            <div
              className="nenda"
              onClick={() => {
                handleSaveClick();
              }}
            >
              <div className="chongoi_1">
                <div className="username_top">{info.username}</div>
                <img alt="user" src="/images/fish/user-cau-ca.gif" />
                {quangCau === 3 && (
                  <img
                    alt="user"
                    src="/images/fish/cacancau.png"
                    style={{
                      marginBottom: "-35px",
                      marginLeft: "-4px",
                    }}
                  />
                )}
                {(quangCau === 4 || quangCau === 2) && (
                  <img
                    alt="icon"
                    src="/images/fish/quangcan.gif"
                    style={{
                      marginBottom: "-6px",
                    }}
                  />
                )}
              </div>

              {renderChoNgoi()}
            </div>
            <div className="fish_action_btn_list">
              {quangCau === 2 ? (
                <div
                  className="farm_action_btn"
                  onClick={() => {
                    handleGiatCa();
                  }}
                >
                  Giật cá
                </div>
              ) : quangCau === 1 || quangCau === 3 ? (
                <div
                  onClick={() => {
                    handleQuangCau();
                  }}
                  className="farm_action_btn"
                  style={{
                    backgroundColor: "rgb(220, 53, 69)",
                  }}
                >
                  Quăng câu
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* <div className="honuoc">
            <div className="vaocau">
              <a href="fish.php">
                <img src="/images/fish/vao.gif" alt="vao" />
              </a>
            </div>
          </div> */}
          {/* <div className="da">
            <div className="shop_user">
              <img
                className="shop_user_image npcanhbakhia"
                src="/images/fish/npcanhbakhia.gif"
                alt="npcanhbakhia"
                onClick={() => {
                  setShowStock(true);
                }}
              />
              <img
                className="shop_user_image"
                src="/images/fish/shopcauca.png"
                alt="shopcauca"
                onClick={() => {
                  setShowStore(true);
                }}
              />
              <img
                className="shop_user_image bxh"
                src="/images/fish/bxh.png"
                alt="bxh"
              />
              <img
                className="shop_user_image den"
                src="/images/fish/den.png"
                alt="den"
              />
            </div>
          </div> */}
        </div>
        <div
          style={{
            position: "relative",
          }}
          className="list_name_no_border"
        >
          Hướng dẫn câu cá
        </div>
        <div className="farm_action">
          <div className="fish_notify">
            <div
              style={{
                color: "rgb(40, 167, 69)",
              }}
            >
              <b>Tình hình hồ câu hiện tại:</b>
            </div>
            <div
              style={{
                textAlign: "left",
                margin: "8px 0px",
              }}
            >
              {fishList.map((fish, index) => (
                <div key={index}>
                  - Hiện còn <b style={{ color: "red" }}>{fish.remain}</b> con{" "}
                  {fish.fish_name} trong hồ.
                </div>
              ))}
              {!dungCuCauCa || (dungCuCauCa && dungCuCauCa.length === 0) ? (
                <div>- Bạn chưa trang bị cần câu cá và mồi câu.</div>
              ) : (
                dungCuCauCa.map((dung_cu, index) => (
                  <div
                    style={{
                      margin: "5px 0px",
                    }}
                    key={index}
                  >
                    {dung_cu.ShopItem.ShopCategory.shop_category_code ===
                    "CAN_CAU_CA" ? (
                      <span>
                        + Cần câu đang dùng:{" "}
                        <b style={{ color: "red" }}>
                          {dung_cu.ShopItem.shop_item_name}
                        </b>
                      </span>
                    ) : (
                      <span>
                        + Mồi đang dùng:{" "}
                        <b style={{ color: "red" }}>
                          {dung_cu.ShopItem.shop_item_name}
                        </b>{" "}
                        còn lại {dung_cu.quanty}.
                      </span>
                    )}
                  </div>
                ))
              )}
            </div>
            Hàng tuần có thể sẽ diễn ra Event đua Top câu cá để tìm ra{" "}
            <b
              style={{
                color: "rgb(220, 53, 69)",
              }}
            >
              Cao Thủ Câu Cá Xuất Sắc
            </b>{" "}
            nhất vùng. Phần thưởng là thẻ cào điện thoại, tiền mặt thông qua
            chuyển khoản Ví Momo, ZaloPay hoặc ngân hàng nhé!
          </div>
          <div
            style={{
              marginTop: 10,
            }}
            className="farm_action_btn_list"
          >
            <div className="farm_action_btn">B1: Quăng câu</div>
            <div className="farm_action_btn">B2: Chờ cắn câu</div>
            <div className="farm_action_btn">B3: Giật cá</div>
          </div>
        </div>
        <div className="bxh">
          <div
            className="list_name_no_border"
            style={{
              marginTop: 10,
              borderRadius: 5,
            }}
          >
            Bảng xếp hạng câu cá
          </div>
          <BangXepHangCauCa bxhCauCa={bxhCauCa} info={info} />

          <div
            style={{
              textAlign: "center",
              marginTop: 10,
            }}
          >
            <Link
              style={{
                color: "#fff",
              }}
              className="farm_action_btn"
              to={"/chat"}
            >
              Khu vực chém gió :D
            </Link>
          </div>
        </div>
        <Footer margin_top={"15px"} />
      </div>
      <MultiplexAd />
    </Fragment>
  );
}
